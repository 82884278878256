import React from "react"

import SiteLayout from "../components/layouts/SiteLayout"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <SiteLayout>
    <SEO title="404: Not found"/>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </SiteLayout>
)

export default NotFoundPage
